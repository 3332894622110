var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-select',_vm._g(_vm._b({attrs:{"menu-props":{ offsetY: true, overflowY: true, closeOnContentClick: true },"items":_vm.militaryServiceStatuses,"item-text":"text","item-value":"value"},on:{"input":function($event){return _vm.$emit('input', $event)}},scopedSlots:_vm._u([(_vm.$attrs.hasOwnProperty('multiple'))?{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('div',[_vm._v(" "+_vm._s(index === 0 ? ((_vm.selected.length) + " " + (_vm.$t("global.selected", [_vm.$t("hr.employee.profile.personal.military_service")]))) : "")+" ")]):_vm._e()]}}:{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("military_service." + (item.toLowerCase()))))+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("military_service." + (item.toLowerCase()))))+" ")]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},'v-select',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }