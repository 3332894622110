<template>
  <v-select
    v-bind="$attrs"
    v-on="$listeners"
    v-model="selected"
    :menu-props="{ offsetY: true, overflowY: true, closeOnContentClick: true }"
    :items="militaryServiceStatuses"
    item-text="text"
    item-value="value"
    @input="$emit('input', $event)"
  >
    <template v-if="$attrs.hasOwnProperty('multiple')" v-slot:selection="{ item, index }">
      <div v-if="index === 0">
        {{
          index === 0
            ? `${selected.length} ${$t("global.selected", [$t("hr.employee.profile.personal.military_service")])}`
            : ""
        }}
      </div>
    </template>
    <template v-else slot="selection" slot-scope="{ item }">
      {{ $t(`military_service.${item.toLowerCase()}`) }}
    </template>
    <template slot="item" slot-scope="{ item }">
      {{ $t(`military_service.${item.toLowerCase()}`) }}
    </template>
  </v-select>
</template>

<script>
  import militaryServiceStatuses from "./militaryServiceStatuses";

  export default {
    name: "MilitaryServicePicker",
    props: {
      value: String
    },
    data: () => ({
      militaryServiceStatuses,
      selected: null
    }),
    watch: {
      value: {
        handler(v) {
          this.selected = v;
        },
        immediate: true
      }
    }
  };
</script>

<style scoped></style>
